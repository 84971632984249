import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	IAppWillMountHandler,
	FeatureExportsSymbol,
	ReducedMotionSymbol,
	ViewerModelSym,
	ViewerModel,
} from '@wix/thunderbolt-symbols'
import { IFeatureExportsStore } from 'thunderbolt-feature-exports'
import { name } from './symbols'

const exportsName = 'env'

export const EnvFactory = withDependencies(
	[named(FeatureExportsSymbol, name), ReducedMotionSymbol, ViewerModelSym],
	(
		envExports: IFeatureExportsStore<typeof exportsName>,
		reducedMotion: boolean,
		viewerModel: ViewerModel
	): IAppWillMountHandler => {
		return {
			appWillMount() {
				envExports.export({ reducedMotion, userId: viewerModel.site.userId })
			},
		}
	}
)
